<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <img class="topLeft" src="../../assets/images/SAFETY-CORNER.svg"/>

  <div class="justify-all">

    <img class="safety-logo" src="../../assets/images/safety_2_logo_white.svg"/>

    <mat-card class="login-card">
      <img class="topLeft-inCard" src="../../assets/images/SAFETY-CORNER.svg"/>
      <!--    <img width="100" src="assets/images/navigationIcons/Logo.png">-->
      <!--    <img width="100" src="assets/images/navigationIcons/company.png">-->
      <mat-card-header class="no-text-margin center-header">
        <h2>{{ 'safety²-EHS Software' }}</h2>
      </mat-card-header>
      <mat-card-subtitle class="center-header">
        <h3>{{ 'Anmeldung' }}</h3>
      </mat-card-subtitle>
      <mat-card-content>
        <div fxLayoutGap="8px" class="full-width">
          <mat-form-field class="half-width" appearance="outline" hideRequiredMarker>
            <mat-label>{{ 'username' | translate }}</mat-label>
            <input type="text" formControlName="login" matInput required autocomplete="off">
          </mat-form-field>
          <mat-form-field class="half-width" appearance="outline" hideRequiredMarker>
            <mat-label>{{ 'password' | translate }}</mat-label>
            <input type="password" formControlName="password" matInput required autocomplete="off">
          </mat-form-field>
        </div>
        <!--      <div class="inline">-->
        <div class="center-toggle" fxLayoutAlign="center">
          <mat-slide-toggle formControlName="saveSession">{{ 'stayLoggedIn' | translate }}</mat-slide-toggle>
        </div>
        <!--          <p (click)="forgetPasswordDialog()"><u>{{'forgetPasswordDescription'| translate}}</u></p>-->
        <!--      </div>-->
        <!--  Hidden new-password input field to disable autocomplete  -->
        <input type="password" autocomplete="new-password" class="hidden">
        <div fxLayoutAlign="center">
          <button class="half-width button-color" fxFlex="0 0 calc(50% - 4px)" mat-raised-button type="submit"
                  color="primary">{{ 'login' | translate }}</button>
        </div>
        <div class="center-passwordForgot">
          <p (click)="forgetPasswordDialog()"><u>{{'forgetPasswordDescription'| translate}}</u></p>
        </div>
        <div class="center-versionInfo">
          <p><u>{{'Version ' + packageJson.version}}</u></p>
        </div>
      </mat-card-content>
      <img class="bottomRight-inCard" src="../../assets/images/SAFETY-CORNER.svg"/>
    </mat-card>

    <div class="company-logo">
      <img class="company-image" *ngIf="avatarURL" [src]="avatarURL" alt="{{''}}">
    </div>
  </div>


  <img class="bottomRight" src="../../assets/images/SAFETY-CORNER.svg"/>

</form>
